import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import styling from '../../styling';
import CloseIconPath from '../../media/icons/times-light.svg';

const StyledBanner = styled.div`
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: hsl(33, 80%, 65%);
  box-shadow: ${styling.miscellaneous.shadowLevel1};
  border-radius: ${styling.sizes.borderRadius};
  z-index: 1;

  button {
    margin-left: 2em;
    width: 1.5em;
    height: 1.5em;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    line-height: 1;
  }

  img {
    width: 1.5em;
    height: 1.5em;
  }
`;

const StyledBannerLink = styled(Link)`
  font-size: 0.9em;
  font-weight: 500;
  color: ${styling.colors.text.body};
`;

interface Props {
  location: Location;
}

interface State {
  hideBanner: boolean;
}

const bannerId = 1;
const bannerLink = '/blogposts/dream-journal-project-beginnings';
const bannerMessage = (
  <span>
    Check out my latest blogpost series{' '}
    <Link to={bannerLink}>An Indie Hacker's Story - Two Years of Developing a No-Fluff Dream Journal</Link>
  </span>
);

class Banner extends React.Component<Props, State> {
  public state = {
    hideBanner: true
  };

  public componentDidMount() {
    this.hideBannerIfClosed();
  }

  public componentDidUpdate() {
    this.hideBannerIfClosed();
  }

  private hideBannerIfClosed() {
    const clearedPathname = this.props.location.pathname.endsWith('/')
      ? this.props.location.pathname.slice(0, -1)
      : this.props.location.pathname;
    const clearedBannerLink = bannerLink.endsWith('/') ? bannerLink.slice(0, -1) : bannerLink;

    const closedBannersJson = localStorage.getItem('closedBanners');
    const closedBanners = closedBannersJson != null ? JSON.parse(closedBannersJson) : [];

    if (!this.state.hideBanner && (closedBanners.includes(bannerId) || clearedPathname === clearedBannerLink)) {
      this.closeBanner();
    }
  }

  private closeBanner() {
    const closedBannersJson = localStorage.getItem('closedBanners');
    const closedBanners = closedBannersJson != null ? JSON.parse(closedBannersJson) : [];
    if (!closedBanners.includes(bannerId)) {
      closedBanners.push(bannerId);
    }
    localStorage.setItem('closedBanners', JSON.stringify(closedBanners));
    this.setState({
      hideBanner: true
    });
  }

  public render() {
    if (this.state.hideBanner) {
      return null;
    }

    return (
      <StyledBanner>
        <StyledBannerLink to={bannerLink} onClick={() => this.closeBanner()}>
          {bannerMessage}
        </StyledBannerLink>
        <button onClick={() => this.closeBanner()}>
          <img src={CloseIconPath} />
        </button>
      </StyledBanner>
    );
  }
}

export default Banner;
