import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Seo, { Props as SeoProps } from './seo';
import Banner from './banner';
import Header, { Page } from './header';
import 'normalize.css';
import styled, { createGlobalStyle } from 'styled-components';
import styling from '../../styling';
import Footer from './footer';
import GoogleAnalytics from './googleAnalytics';

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

* {
  box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
    font-family: 'Roboto', sans-serif;
    color: ${styling.colors.text.body};
    line-height: 1.75;
    font-display: swap;
}

#___gatsby {
    height: 100%;
}

#___gatsby > div {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  color: ${styling.colors.text.headline};
  letter-spacing: 0.03rem;
}

a {
  color: ${styling.colors.link.body};
  text-decoration: none;
}
`;

const StyledContentArea = styled.div`
  margin-top: 2.75em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsla(0, 100%, 100%, 70%);
`;

const StyledContent = styled.div`
  padding: 1em ${styling.sizes.contentWidthPadding};
  width: 100%;
  max-width: ${styling.sizes.contentWidth};
  background-color: hsla(0, 100%, 100%, 70%);
  ${styling.sizes.adaptedSizes}
`;

interface Props extends SeoProps {
  children: React.ReactNode;
  currentPage: Page;
  location: Location;
}

const Layout = (props: Props) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <>
        <GoogleAnalytics />
        <GlobalStyle />
        <Seo {...props} title={props.title || data.site.siteMetadata.title} />
        <Header currentPage={props.currentPage} />
        <StyledContentArea>
          <StyledContent>
            <Banner location={props.location} />
            {props.children}
          </StyledContent>
        </StyledContentArea>
        <Footer />
      </>
    )}
  />
);

export default Layout;
