import React, { Component } from 'react';

const googleAnalyticsSetup = () => {
  ((i: any, s: any, o: any, g: any, r: any, a?: any, m?: any) => {
    i.GoogleAnalyticsObject = r;
    (i[r] =
      i[r] ||
      (() => {
        (i[r].q = i[r].q || []).push(arguments);
      })),
      (i[r].l = 1 * (new Date() as any));
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

  ga('create', 'UA-4944338-3', 'auto');
  ga('set', 'anonymizeIp', true);
  ga('send', 'pageview');

  window.onunload = () => {
    ga('send', 'event', 'navigation', 'leave site or navigate');
  };
};

let mounted = false;

class GoogleAnalytics extends Component {
  componentDidMount() {
    if (mounted) {
      return;
    }
    googleAnalyticsSetup();
    mounted = true;
  }

  render() {
    return <script src="https://www.google-analytics.com/analytics.js" async={true} />;
  }
}
export default GoogleAnalytics;
