import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import styling from '../../styling';
import LogoIcon from '../../media/icons/logo.inline.svg';
import TwitterIconPath from '../../media/icons/twitter-brands-light.svg';

export enum Page {
  Blog,
  Blogpost,
  Projects,
  AboutMe,
  BookNotes,
  Resources
}

const StyledHeaderArea = styled.header`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${styling.colors.primary.L20};
  box-shadow: ${styling.miscellaneous.shadowLevel2};
  z-index: 1;
`;

const StyledHeader = styled.nav`
  padding: 0.5em ${styling.sizes.contentWidthPadding};
  width: 100%;
  max-width: ${styling.sizes.contentWidth};
  display: flex;
  justify-content: space-between;
  ${styling.sizes.adaptedSizes}

  > div {
    display: flex;
    align-items: center;
  }
`;

interface StyledLinkProps {
  isCurrent: boolean;
}

const StyledLink = styled(Link)`
  text-decoration: 'none';
  color: ${styling.colors.primary.L95};
  border-bottom: ${(props: StyledLinkProps) => (props.isCurrent ? `1px solid` : 'none')};
  transition: ${styling.miscellaneous.transition};
  letter-spacing: 0.03rem;

  ${(props) =>
    !props.isCurrent
      ? ''
      : css`
          border-bottom: 1px solid;
          font-weight: 500;
        `}

  &:visited {
    color: ${styling.colors.primary.L95};
  }

  &:hover {
    color: ${styling.colors.primary.L80};
  }

  :not(:last-child) {
    margin-right: 1.5em;
  }
`;

const StyledLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledMainLink = styled(StyledLink)`
  margin-right: 2em !important;
  display: inline-block;
  line-height: 1;

  > svg {
    height: 1.5em;
  }
`;

const StyledSocialMediaLink = styled.a`
  line-height: 1;

  > img {
    height: 1.5em;
  }
`;

interface Props {
  currentPage: Page;
}

class Header extends PureComponent<Props> {
  public render() {
    return (
      <StyledHeaderArea>
        <StyledHeader>
          <div>
            <StyledMainLink to="/" isCurrent={false}>
              <LogoIcon />
            </StyledMainLink>
            <StyledLinks>
              <StyledLink to="/blogposts" isCurrent={this.props.currentPage === Page.Blog}>
                Blog
              </StyledLink>
              <StyledLink to="/projects" isCurrent={this.props.currentPage === Page.Projects}>
                Projects
              </StyledLink>
              <StyledLink to="/book-notes" isCurrent={this.props.currentPage === Page.BookNotes}>
                Book Notes
              </StyledLink>
              <StyledLink to="/resources" isCurrent={this.props.currentPage === Page.Resources}>
                Resources
              </StyledLink>
              <StyledLink to="/about-me" isCurrent={this.props.currentPage === Page.AboutMe}>
                About Me
              </StyledLink>
            </StyledLinks>
          </div>
          <div>
            <StyledSocialMediaLink href="https://twitter.com/domysee" target="_blank">
              <img alt="Twitter Icon" src={TwitterIconPath} />
            </StyledSocialMediaLink>
          </div>
        </StyledHeader>
      </StyledHeaderArea>
    );
  }
}

export default Header;
