import React, { PureComponent } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import styling from '../../styling';

const StyledFooterArea = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${styling.colors.primary.L30};
  color: ${styling.colors.primary.L95};
`;

const StyledFooter = styled.footer`
  padding: 0.5em ${styling.sizes.contentWidthPadding};
  width: 100%;
  max-width: ${styling.sizes.contentWidth};
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.03rem;
  ${styling.sizes.adaptedSizes}
`;

class Footer extends PureComponent {
  public render() {
    return (
      <StyledFooterArea>
        <StyledFooter>
          <span>© {new Date().getFullYear()} Dominik Weber</span>
          <div></div>
        </StyledFooter>
      </StyledFooterArea>
    );
  }
}

export default Footer;
