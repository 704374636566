import { css } from 'styled-components';

const colors = {
  primary: {
    vibrant: 'hsl(218,100%,40%)',
    normal: 'hsl(218,46%,40%)',
    L10: 'hsl(218,46%,10%)',
    L20: 'hsl(218,46%,20%)',
    L30: 'hsl(218,46%,30%)',
    L40: 'hsl(218,46%,40%)',
    L50: 'hsl(218,46%,50%)',
    L60: 'hsl(218,46%,60%)',
    L70: 'hsl(218,46%,70%)',
    L80: 'hsl(218,46%,80%)',
    L90: 'hsl(218,46%,90%)',
    L95: 'hsl(218,46%,95%)'
  },
  gray: {
    L10: 'hsl(228,5%,10%)',
    L21: 'hsl(228,5%,21%)',
    L32: 'hsl(228,5%,32%)',
    L43: 'hsl(228,5%,43%)',
    L54: 'hsl(228,5%,54%)',
    L65: 'hsl(228,5%,65%)',
    L76: 'hsl(228,5%,76%)',
    L87: 'hsl(228,5%,87%)',
    L95: 'hsl(228,5%,95%)'
  },
  green: {
    L15: 'hsl(130,66%,15%)',
    L30: 'hsl(130,66%,30%)',
    L45: 'hsl(130,66%,45%)',
    L60: 'hsl(130,66%,60%)',
    L75: 'hsl(130,66%,75%)',
    L90: 'hsl(130,66%,90%)'
  },
  yellow: {
    L20: 'hsl(51,80%,20%)',
    L33: 'hsl(51,80%,33%)',
    L46: 'hsl(51,80%,46%)',
    L59: 'hsl(51,80%,59%)',
    L72: 'hsl(51,80%,72%)',
    L85: 'hsl(51,80%,85%)'
  },
  red: {
    L15: 'hsl(0,66%,15%)',
    L31: 'hsl(0,66%,31%)',
    L47: 'hsl(0,66%,47%)',
    L63: 'hsl(0,66%,63%)',
    L79: 'hsl(0,66%,79%)',
    L95: 'hsl(0,66%,95%)'
  }
};

const sizes = {
  contentWidth: '55em',
  contentWidthNumber: 55,
  contentWidthPadding: '8em',
  contentWidthPaddingNumber: 8,
  borderRadius: '0.25em'
};

const adaptedSizes = css`
  @media (max-width: ${sizes.contentWidthNumber * 0.9}em) {
    padding-left: ${sizes.contentWidthPaddingNumber * 0.8}em;
    padding-right: ${sizes.contentWidthPaddingNumber * 0.8}em;
  }

  @media (max-width: ${sizes.contentWidthNumber * 0.8}em) {
    padding-left: ${sizes.contentWidthPaddingNumber * 0.6}em;
    padding-right: ${sizes.contentWidthPaddingNumber * 0.6}em;
  }

  @media (max-width: ${sizes.contentWidthNumber * 0.7}em) {
    padding-left: ${sizes.contentWidthPaddingNumber * 0.4}em;
    padding-right: ${sizes.contentWidthPaddingNumber * 0.4}em;
  }

  @media (max-width: ${sizes.contentWidthNumber * 0.6}em) {
    padding-left: ${sizes.contentWidthPaddingNumber * 0.2}em;
    padding-right: ${sizes.contentWidthPaddingNumber * 0.2}em;
  }

  @media (max-width: ${sizes.contentWidthNumber * 0.5}em) {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

const styling = {
  miscellaneous: {
    transition: 'all 0.25s ease-in-out',
    shadowLevel1: '0 0.125em 0.5em hsla(218,46%,30%,50%)',
    shadowLevel2: '0 0.125em 1.5em hsla(218,46%,30%,50%)'
  },
  sizes: {
    ...sizes,
    adaptedSizes
  },
  colors: {
    ...colors,
    text: {
      headline: colors.primary.L10,
      body: colors.gray.L21
    },
    link: {
      headline: colors.primary.vibrant,
      body: colors.primary.L50
    }
  }
};

export default styling;
